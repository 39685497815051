import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import sidebarIndex from '@/components/Sidebar.vue';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      isFooter:false,
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      
      isFooter:true,
    },
  },
  {
    path:'/personal',
    name:"personalIndex",
    // component:{
    //   default: () => {import(/* webpackChunkName: "about" */ '../views/personal/index.vue')},
    //   // Sidebar:sidebarIndex,
    //   // isSidebar:true,
    // }
    component:()=>
      import(/* webpackChunkName: "about" */ '../views/personal/index.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/record',
    name:"recordIndex",
    component:()=>
      import('../views/record/index.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/invite',
    name:"inviteIndex",
    component:()=>
      import('../views/invite/index.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/setup',
    name:"setupIndex",
    component:()=>
      import('../views/setup/index.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/info/index',
    name:"infoIndex",
    component:()=>
      import('../views/info/index.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/addVirtual',
    name:"addVirtual",
    component:()=>
      import('../views/personal/addVirtual.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/addEbpay',
    name:"addEbpay",
    component:()=>
      import('../views/personal/addEbpay.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/addLink',
    name:"addLink",
    component:()=>
      import('../views/personal/addLink.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/infoContent',
    name:"infoContent",
    component:()=>
      import('../views/info/infoContent.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/welfare/index',
    name:"welfareIndex",
    component:()=>
      import('../views/record/welfare.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/record/postRecord',
    name:"postRecordIndex",
    component:()=>
      import('../views/record/postRecord.vue'),
      meta: {
        isSidebar: true,
        isFooter:true,
      },
    
  },
  {
    path:'/classIndex',
    name:"classIndex",
    component:()=>
      import('../views/class/index.vue'),
      meta: {
        isSidebar: false,
        isFooter:false,
      },
    
  },
  {
    path: "/vip",
    name: "vipIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vip/index.vue"),
    meta: {
      isFooter: false,
      isSidebar: true, 
    },
  },
  {
    path: "/details",
    name: "discountIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discount/index.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },
  {
    path: "/sportsActivity",
    name: "sportsActivity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discount/sportsActivity.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discount/activity.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },
  {
    path: "/activityRule",
    name: "activityRule",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discount/activityRule.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },
  {
    path: "/cooperation",
    name: "cooperationIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/cooperation/index.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },
  {
    path: "/share/index",
    name: "shareIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/share/index.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },
  {
    path: "/perfectionActivity",
    name: "perfectionActivity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discount/perfectionActivity.vue"),
    meta: {
      isFooter: true,
      isSidebar: false, 
    },
  },

  
  
]

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function  push(to) {
  return VueRouterPush.call(this,to).catch(err => err)
}

const scrollBehavior = (to,from,savedPosition) =>{
  
  if(savedPosition && to.meta.keepAlive){
    return savedPosition
  }
  return { x: 0, y: 0 };
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (router.app.$route && to.path !== router.app.$route.path) {
    const subjectDemo = document.querySelector("#subjectDemo");
    if(subjectDemo){
      subjectDemo.scrollTo(0,0)
    }
  }
  // console.log(to,from)
  // if(to.name == 'classIndex' && from.name == 'classIndex'){
  //   next();
  //   window.location.reload()
  //   return;
  // } 

  // 否则继续跳转
  next(); 
});

export default router
